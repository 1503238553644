<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<Head></Head>
			<div class="content">
				<div class="wrapper">
					<div class="swipe">
						<!-- {{swipe}} -->
						<el-carousel height="350px">
							<el-carousel-item v-for="item in swipe" :key="item.id">
								<!-- <h3 class="small">{{ item }}</h3> -->
								<img style="width: 100%;" :src="item.url" alt="">
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="product_title">产品展示</div>
					<div class="plates">
						<div class="plate" v-for="item in titleData" :key="item.id">
							<div>
								<img class="plate_img" :src="item.url" alt="">
								<div class="plate_title">{{item.title}}</div>
							</div>
						</div>
					</div>
					<div class="product_title">中爱一生APP功能说明</div>
					<div class="plates">
						1、商品展示<br>
						商品展示是商城平台系统一个基本且十分重要的功能，毕竟企业建立电商平台系统就是为了能够展示产品达到销售的目的，用户进入企业的电电商平台系统，应该像进入现实中的超市一样，能够看到琳琅满目的商品。<br>
						
						利用网络媒体进行产品的推销，无疑使企业多了一条很有前途的营销渠道。这些商品是经过分类的，就像超市中将商品分为服装类、副食类、家电类等一样。企业可以在电商平台系统上对某些商品开展广告促销活动。<br>
						2、会员管理<br>
						
						会员是电商平台系统的稳定用户群体，商城的收益有一部分是来源与会员，因此，电商平台系统的建设，会员功能一定要具备的。电商平台系统只要有新用户注册和登录，用户在访问平台的时候，可以看到更加详细的内容。同时企业也可以掌握用户的信息，根据他们的需求有针对性地进行推送产品。<br>
						3、订单管理<br>
						
						订单管理这个功能的设置是根据用户的心理出发的，能够有效的缓解用户的焦急心理，在个人中心中查看自己的订单，同时电商平台系统应对接第三方快递的查询接口，可以查询用户商品的物流信息，使用户确定自己商品的物流信息。<br>
						订单列表筛选功能强大，订单状态准确清晰，订单详情更完整记录电商平台系统订单所有相关信息流程。<br>
						4、网上支付<br>
						
						企业做了那么多，就是为了达到成交这一最终目的，电子商务要有一个完整的过程，网上支付是重要的环节。客户和商家之间可采用信用卡、支付宝等多种方式实施支付。<br>
						
						在直接采用电子支付手段将可以节省交易费用。但电子支付将需要更为可靠的信息传输安全性，以防止欺骗、窃听、冒用等非法行为
					</div>
					<div class="footer">
						<Foot></Foot>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/20230710151453863ac9683.png",
					},

				],
				titleData: [
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202308171412296913a0745.jpg",
						"title": "国甄（中爱一生30）纯粮酱香53°"
					},

					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202308191134360461d0631.jpg",
						"title": "金镶玉龙牌（足金+和田玉）"
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/20230819161643e20279232.jpg",
						"title": "翡翠貔貅"
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/20230819161944290ac9081.jpg",
						"title": "珠串龙纹牌（翡翠）"
					},
					
					
					
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202308171336158af394100.jpg",
						"title": "微V通营养素细胞赋活饮"
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202308171340394b0272903.jpg",
						"title": "常清轻益生菌"
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/20230817140715ffe371791.jpg",
						"title": "福硒宝复配营养强化剂"
					},
					
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202308171405190ca0b2452.jpg",
						"title": "So耶N+特殊膳食"
					},

				]
			};
		},
		methods: {
			// 引入封装好的工具类
			getSwipeData() {
				get("/index/carousel/findAll").then((res) => {
					this.swipe = res.data;
				});
			},
			gettitleData() {
				get('/index/article/findCategoryArticles').then(res => {
					this.titleData = res.data;
				})
			}
		},
		created() {
			// this.getSwipeData();
			// this.gettitleData()
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {
			width: 85%;

			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;

					.plate_img {
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}

					.plate_title {
						margin-top: 15px;
					}
				}
			}
		}

		.product_title {
			font-size: 30px;
			font-weight: 600;
			margin: 20px;
		}
	}

	.footer {

		margin-top: 40px;
		height: 200px;
		// background-color: #528c38;
	}
</style>